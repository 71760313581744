<template>
  <div class="req-temp-container">
    <vs-card class="mt-6">
      <div slot="header">
        <div class="flex items-center">
          <h3 class="font-medium">Bank accounts</h3>
        </div>
      </div>
      <div>
        <vs-row>
          <vs-col v-for="(bank, key) in bankAccounts" :key="key" vs-w="4" class="p-2">
            <vs-card class="mt-6 bankCard">
              <div slot="header">
                <div class="items-center">
                  <h5 class="font-medium mb-2">{{ bank.accountName }}</h5>
                  <p>
                    <span>Account ID: {{ bank.bankAccountId }}</span>
                  </p>
                </div>
              </div>
              <div>
                <div class="flex">
                  <div class="w-full">
                    <label class="vs-input--label">Display name</label>
                    <vs-input class="darkBlue font-normal" name="displayName" v-model="bank.displayName" @input="checkForm" />
                  </div>
                </div>
                <div class="flex mt-8">
                  <div class="w-full">
                    <label class="vs-input--label">Account name</label>
                    <p class="darkBlue font-normal">{{ bank.accountName }}</p>
                  </div>
                </div>
                <div class="flex mt-8">
                  <div class="w-full">
                    <label class="vs-input--label">BSB</label>
                    <p class="darkBlue font-normal">
                      {{ `${bank.bsb[0]}-${bank.bsb[1]}` }}
                    </p>
                  </div>
                  <div class="w-full">
                    <label class="vs-input--label">Account number</label>
                    <p class="darkBlue font-normal">{{ bank.accountNumber }}</p>
                  </div>
                </div>
              </div>

              <div class="flex mt-8">
                <div class="w-full">
                  <label class="vs-input--label">Default remittance advice to</label>
                  <vs-input class="darkBlue font-normal" :name="`remittanceAdviceEmail${key}`" v-model="bank.remittanceAdviceEmail" @input="checkForm" v-validate="'required|email'" data-vv-as="remittance advice" />
                  <span class="text-danger text-sm" v-show="errors.has(`remittanceAdviceEmail${key}`)">{{ errors.first(`remittanceAdviceEmail${key}`) }}</span>
                </div>
              </div>
            </vs-card>
          </vs-col>
        </vs-row>

        <vs-row class="block">
          <div class="button-section">
            <vs-button class="primary lg:mr-8 mr-4" @click="updateDisplayName()" :disabled="isValidField || !validateForm" size="medium" v-round >Save</vs-button>
            <div class="mid-blue" @click="$router.push({ name: 'settings' })">
              <u>Cancel</u>
            </div>
          </div>
        </vs-row>
      </div>
    </vs-card>
    <leave-popup :popup-active="popupActive" @handleClick="popActions"></leave-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LeavePopup from "@/views/components/LeavePopup";
import { leavePopUpMixin } from "@/mixins/leave-pop-up";

export default {
  mixins: [leavePopUpMixin],
  components: { LeavePopup },
  name: "BankAccounts",
  data() {
    return {
      bankAccounts: {},
      isSaved: false,
      nextObj: "", // determines the next route,
      popupActive: false, // determines if the leave popup is to be shown
      checkFormDirty: false,
    };
  },
  methods: {
    ...mapActions("bank", ["fetchBankByPartnerId", "updateDisplayNameByPartnerId"]),

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    async getBankAccounts() {
      this.$vs.loading();
      const partnerId = this.user.userType == "admin" ? this.user._id : this.user.partnerId;
      await this.fetchBankByPartnerId(partnerId).then((response) => {
        this.bankAccounts = response.data.data.filter(item => item.isUsedForSettlement);
        this.bankAccounts.map((item) => {
          item.bsb = item.bsb.split(" ").join("");
          item.bsb = item.bsb.match(/.{1,3}/g);
        });
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showMessage(ex.response.data.title || "Bank", ex.response.data.message, "danger", "icon-check-circle");

        if (ex.response.status === 400) {
          this.$router.push({ name: "settings" });
        }
      });
    },

    async updateDisplayName() {
      this.$vs.loading();
      const bankDetail = this.bankAccounts;
      await this.updateDisplayNameByPartnerId(bankDetail).then((response) => {
        this.$vs.loading.close();
        this.showMessage("Success", "Bank account saved successfully.", "success", "icon-check-circle");
        this.isSaved = true;
        this.$router.push({ name: "settings" });
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showMessage("Error", ex.message, "danger", "icon-times");
      });
    },

    checkForm() {
      this.checkFormDirty = true;
    }
  },

  beforeRouteLeave(to, from, next) {
    if ((this.isFormDirty || this.checkFormDirty) && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
      localStorage.setItem("redirectTo", to.name || undefined);
    } else {
      next();
    }
  },

  watch: {
    isFormDirty(latest, previous) {
      if (latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    isSaved(latest, previous) {
      if (!latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    checkFormDirty(latest, previous) {
      if (latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    }
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },

    isValidField() {
      let status = false;

      if (this.bankAccounts) {
        this.bankAccounts.map((item) => {
          if (item.displayName === "") {
            status = true;
          }
        });
      }

      return status;
    },

    validateForm() {
      return !this.errors.any();
    },
  },

  mounted() {
    this.getBankAccounts();
  },
};
</script>

